<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />
      <div class="filter-bar boxarea">
        <SelectLayer />
      </div>
      <div class="customer-insight boxarea">
        <div class="filter-button">
          <button @click="handleMarketing">{{ $t("use_marketing") }}</button>
          <!-- <button>제품 R&#38;D 활용</button>
          <button>고객 프로모션 활용</button>-->
        </div>
        <table class="infinite-list-wrapper" v-infinite-scroll="load">
          <tr>
            <td>
              <!-- <input type="checkbox" @change="handleAllCheck($event)" /> -->
            </td>
            <td>
              <router-link to @click.native="handleSort('date')">
                DATE
                <span v-if="date == -1">▼</span>
                <span v-else>▲</span>
              </router-link>
            </td>
            <td>
              <router-link to @click.native="handleSort('source')">
                SOURCE
                <span v-if="source == -1">▼</span>
                <span v-else>▲</span>
              </router-link>
            </td>
            <td>
              <router-link to @click.native="handleSort('rate')">
                RATE
                <span v-if="rate == -1">▼</span>
                <span v-else>▲</span>
              </router-link>
            </td>
            <td>REVIEW</td>
            <td>
              <router-link to @click.native="handleSort('nps')">
                NPS {{ $t("score") }}
                <span v-if="nps == -1">▼</span>
                <span v-else>▲</span>
              </router-link>
            </td>
            <!-- <td>아이디어 점수</td>
            <td>고객참여 점수</td>-->
          </tr>
          <tr v-for="(item, i) in reviewList" :key="i">
            <td>
              <input
                type="checkbox"
                :checked="checkItems.indexOf(item.id) >= 0 ? true : false"
                @change="handleChecked($event, item.id)"
              />
            </td>
            <td>{{ moment(item.review_date).format("YYYY.MM.DD") }}</td>
            <td>{{ item.review_source }}</td>
            <td>{{ item.rating }} / 5</td>
            <td>
              {{ item.review }}
              <p>{{ item.reviewer_name }}</p>
            </td>
            <!-- <td><Small-Donut :dataA="dataA" /></td> -->
            <td>
              <Small-Donut :dataA="[item.NPS_Score]" />
            </td>
            <!-- <td>
              <Small-Donut :dataA="[item.NPS_Score]" />
            </td>
            <td>
              <Small-Donut :dataA="[item.NPS_Score]" />
            </td>-->
          </tr>
        </table>

        <p v-show="loading" class="loading">Loading...</p>
      </div>
    </div>
  </div>
</template>
<script>
import SmallDonut from "@/components/charts/SmallDonut";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import { fetchReviewList } from "@/api/review";
import { format } from "@/mixins/format";
import moment from "moment";
import { mapState } from "vuex";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      dataB: [73],
      dataC: [27],
      reviewList: [],
      checkItems: [],
      currentPage: 0,
      totalPages: 0,
      loading: false,
      sortDate: true,
      source: "",
      date: -1,
      nps: "",
      rate: "",
    };
  },
  components: { SmallDonut, TopBar, Navbar, SelectLayer },
  // mounted() {
  //   this.getReviewList();
  // },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 5);
  },
  computed: { ...mapState(["term"]) },
  watch: {
    term: function () {
      window.console.log("watch : term");
      this.currentPage = 1;
      this.reviewList = [];
      this.source = "";
      this.date = -1;
      this.nps = "";
      this.rate = "";
      this.getReviewList();
    },
  },
  methods: {
    handleAllCheck(e) {
      if (e.target.checked) {
        const idArray = [];
        this.reviewList.forEach((item, index) => idArray.push(index));
        this.checkItems = idArray;
      } else {
        this.checkItems = [];
      }
    },
    handleChecked(e, idx) {
      if (e.target.checked) {
        this.checkItems = [...this.checkItems, idx];
      } else {
        this.checkItems = this.checkItems.filter((id) => idx !== id);
      }
    },
    handleMarketing() {
      if (this.checkItems.length == 0) {
        return alert("1개 이상의 리뷰를 선택해 주세요.");
      }
      let result = [];
      this.checkItems.forEach((item) => {
        this.reviewList.forEach((review) => {
          if (item == review.id) {
            result.push(review);
          }
        });
      });
      this.$store.dispatch("REVIEWDATA", result);
      this.$router.push({ name: "reviewmind6" });
    },
    handleSort(item) {
      switch (item) {
        case "date":
          this.source = "";
          this.rate = "";
          this.nps = "";
          this.date = this.date == 1 ? -1 : 1;
          break;
        case "source":
          this.date = 1;
          this.rate = "";
          this.nps = "";
          this.source = this.source == 1 ? -1 : 1;
          break;
        case "rate":
          this.source = "";
          this.date = "";
          this.nps = "";
          this.rate = this.rate == -1 ? 1 : -1;
          if (this.rate == "") {
            this.rate = -1;
          } else if (this.rate == -1) {
            this.rate = 1;
          } else if (this.rate == 1) {
            this.rate = -1;
          }
          break;
        case "nps":
          this.source = "";
          this.rate = "";
          this.date = "";
          this.nap = this.nap == -1 ? 1 : -1;
          break;
      }
      this.currentPage = 1;
      this.reviewList = [];
      this.getReviewList();
    },
    load() {
      if (this.currentPage > this.totalPages) {
        return;
      }
      this.currentPage++;
      this.getReviewList();
    },
    getReviewList() {
      this.loading = true;

      let params = {
        page: this.currentPage,
        source: this.source,
        date: this.date,
        nps: this.nps,
        rate: this.rate,
        term: this.term,
      };

      fetchReviewList(params).then((res) => {
        if (res.data.status == 200) {
          // if (this.term && this.totalPages > 0) {
          //   window.console.log("1");
          //   this.reviewList = [];
          //   this.currentPage = 1;
          //   this.totalPages = 0;
          // }
          this.reviewList = [...this.reviewList, ...res.data.data];
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
        } else {
          return alert(res.data.message);
        }
        setTimeout(() => {
          this.loading = false;
        }, 6000);
      });
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
